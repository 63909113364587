<template>
  <div>
    <div id="tradingview" :style="'height: '+Height"></div>
  </div>
</template>
    
    <script>
export default {
  name: 'TradingView',
  props: ['TvCode','Height'],
  data() {
    return {
      widget: null,
      symbolInfo: null,
      feed: null,
      loading: false
    }
  },
  async created() {},
  mounted() {
    this.tradingview(this.TvCode)
  },
  methods: {
    async tradingview(symbol) {
      const that = this
      this.$nextTick(function () {
        let widget = new TradingView.widget({
          autosize: true,
          symbol: symbol,
          interval: '1',
          timezone: 'Asia/Shanghai',
          theme: 'dark',
          style: '1',
          locale: 'en_US',
          // datafeed: that.createFeed(),
          toolbar_bg: '#f1f3f6',
          // enable_publishing: false,
          // hide_legend: true,
          save_image: false,
          container_id: 'tradingview',
          fullscreen: true, //显示图表是否占用窗口中所有可用的空间
          studies: [
            {
              id: 'MASimple@tv-basicstudies',
              inputs: {
                length: 5
              }
            },
            {
              id: 'MASimple@tv-basicstudies',
              inputs: {
                length: 10
              }
            },
            {
              id: 'MASimple@tv-basicstudies',
              inputs: {
                length: 30
              }
            }
          ]
        })
        that.widget = widget
        const iframe = document.querySelector('#' + that.widget.id)
        iframe.onload = function () {
          that.loading = true
          that.$emit('TradingLoading', that.loading)
        }
      })
    }
  }
}
</script>
    
    <style scoped>
</style>
    