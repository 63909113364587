import { fetch, post } from '@/utils/axios'

export default {
  getOneSecondPrices: (id) => {
    return fetch(`/Event/${id}/OneSecondPrices`)
  },
  list: () => {
    return fetch('/Event/Products')
  },
  get1M: (id) => {
    return fetch(`/Event/${id}/OneMinutePrices`)
  },
  get3M: (id) => {
    return fetch(`/Event/${id}/ThreeMinutePrices`)
  },
  get5M: (id) => {
    return fetch(`/Event/${id}/FiveMinutePrices`)
  },
  get15M: (id) => {
    return fetch(`/Event/${id}/FifteenMinutePrices`)
  },
  get1Hour: (id) => {
    return fetch(`/Event/${id}/OneHourPrices`)
  },
  getOneDay: (id) => {
    return fetch(`/Event/${id}/DayPrices`)
  },
  createOrder: (data) => {
    return post('/app/Order/create', data)
  },
  lastOrder: () => {
    return fetch('/app/Order/detail')
  },
  orderList: (params) => {
    return fetch('/app/Order', params)
  },
  Average: () => {
    return fetch('/Event/Products/Average')
  },
  OrderCash: (id) => {
    return fetch(`/Event/${id}/OrderCash`)
  },
  Basic: () => {
    return fetch('/Event/Products/Basic')
  },
  getOrderList: (params) => {
    return fetch('/app/Order/position', params)
  },
  getRecordList: (params) => {
    return fetch('/app/Order/record', params)
  },
  getMiningProductist: (params) => {
    return fetch('/app/MiningProduct/products', params)
  },
  getMiningRecords: (params) => {
    return fetch('/app/MiningProduct/records', params)
  },
  createMiningOrder: (data) => {
    return post('/app/MiningProduct/create', data)
  },
  getNewCoin: (params) => {
    return fetch('/app/NewCoin', params)
  },
  getNewCoinRecords: (params) => {
    return fetch('/app/NewCoin/records', params)
  },
  createNewCoinOrder: (data) => {
    return post('/app/NewCoin/create', data)
  },
  createCoinOrder: (data) => {
    return post('/app/CoinOrder/create', data)
  },
  CoinRecord: (params) => {
    return fetch('/app/CoinOrder/record', params)
  },
  CoinProducts: (params) => {
    return fetch('/Event/CoinProducts', params)
  },
  ContractProducts: (params) => {
    return fetch('/Event/ContractProducts', params)
  },
  cancelCoinOrder: (orderNo) => {
    return post(`/app/CoinOrder/${orderNo}/cancel`)
  },
  CoinAllRecord: (params) => {
    return fetch('/app/CoinOrder/all/record', params)
  },
  ContractCreate: (params) => {
    return post('/app/ContractOrder/create', params)
  },
  ContractOrderRecord: (params) => {
    return fetch('/app/ContractOrder/record', params)
  },
  ContractOrderAllRecord: (params) => {
    return fetch('/app/ContractOrder/all/record', params)
  },
  ContractOrderWait: (params) => {
    return fetch('/app/ContractOrder/wait', params)
  },
  cancelContractOrder: (orderNo) => {
    return post(`/app/ContractOrder/${orderNo}/cancel`)
  },
  cancelContractOrderClose: (orderNo) => {
    return post(`/app/ContractOrder/${orderNo}/close`)
  },
  ContractOrderCloseAll: (productId) => {
    return post(`/app/ContractOrder/${productId}/closeAll`)
  },
  hazard: (params) => {
    return fetch('/app/ContractOrder/hazard', params)
  }
}
