/*
 * @Description:
 * @Author: 爱酱丶
 * @Date: 2022-12-08 16:55:47
 * @LastEditTime: 2022-12-11 13:21:09
 * @LastEditors: 爱酱丶
 */
export default {
  methods: {
    dateFormate(time) {
      // let that = this
      /* if (time % 31536000 === 0) {
        // 年
        return `${parseInt(time / 31536000)}${that.$t('pcregister').p15}`
      } else if (time % 2592000 === 0) {
        return `${parseInt(time / 2592000)}${that.$t('pcregister').p16}`
      } else if (time % 86400 === 0) {
        return `${parseInt(time / 86400)}${that.$t('pcregister').p17}`
      } else if (time % 3600 === 0) {
        return `${parseInt(time / 3600)}${that.$t('pcregister').p18}`
      } else if (time % 60 === 0) {
        return `${parseInt(time / 60)}${that.$t('pcregister').p19}`
      } else {
        return `${parseInt(time)}${that.$t('pcregister').p20}`
      } */

      // 防止字符串叠加处理
      if (typeof time === 'string') {
        time = Number(time.substring(0, time.length - 1))
      }
      // if(Object.prototype.toString.call(time))
      if (Number(time) % 3600 === 0) {
        return `${time / 3600}h`
      } else {
        return `${time}s`
      }
      /* let year = 0
      let month = 0
      let day = 0
      let hour = 0
      let minute = 0
      let second = 0
      let val = ''
      if (time < 60) {
        val = time
        return `${val} ${second} ${that.$t('pcregister').p20}`
      } else {
        // 说明满一分钟了
        minute = Math.floor(time / 60)
        second = Math.floor(time % 60)
        if (minute < 60) {
          return `${minute} ${that.$t('pcregister').p19} ${second} ${
            that.$t('pcregister').p20
          } `
        } else {
          // 说明进入小时了
          hour = Math.floor(minute / 60) // 小时数
          minute = Math.floor(minute % 60)
          if (hour < 24) {
            return `${hour} ${that.$t('pcregister').p18} ${minute} ${
              that.$t('pcregister').p19
            } ${second} ${that.$t('pcregister').p20}`
          } else {
            // 说明进入一天了
            day = Math.floor(hour / 24) // 天数
            hour = Math.floor(hour % 24) // 剩余小时数
            if (day < 30) {
              return `${day} ${that.$t('pcregister').p17} ${hour} ${
                that.$t('pcregister').p18
              } ${minute} ${that.$t('pcregister').p19} ${second} ${
                that.$t('pcregister').p20
              } `
            } else {
              // 进入月
              month = Math.floor(day / 30) // 月数
              day = Math.floor(hour % 30) // 剩余天数
              if (month < 12) {
                return `${month} ${that.$t('pcregister').p16} ${day} ${
                  that.$t('pcregister').p17
                } ${hour} ${that.$t('pcregister').p18} ${minute} ${
                  that.$t('pcregister').p19
                } ${second} ${that.$t('pcregister').p20}`
              } else {
                // 年
                year = Math.floor(month / 12)
                month = Math.floor(month % 12)
                return `${year} ${that.$t('pcregister').p15} ${month} ${
                  that.$t('pcregister').p16
                } ${day} ${that.$t('pcregister').p17} ${hour} ${
                  that.$t('pcregister').p18
                } ${minute} ${that.$t('pcregister').p19} ${second} ${
                  that.$t('pcregister').p20
                }`
              }
            }
          }
        }
      } */
    }
  }
}
